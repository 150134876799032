import $ from 'jquery';
import 'bootstrap';
import { CountUp } from 'countup.js';
import 'slick-carousel';
import sal from 'sal.js';

const options = {
  useGrouping: false,
  duration: 4,
};
const counterNum = document.querySelectorAll('.counter-num');
const nums = [3832, 320, 174];
let isDone = false;

const carouselOptions = {
  rows: 0,
};

const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0
    && bounding.left >= 0
    && bounding.bottom
    <= (window.innerHeight || document.documentElement.clientHeight)
    && bounding.right
    <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const runCounter = (items) => {
  items.forEach((num, i) => {
    const countUp = new CountUp(`counter-num-${i + 1}`, nums[i], options);
    countUp.start();
  });
};

document.addEventListener('DOMContentLoaded', () => {
  $('.testimonials-carousel').slick(carouselOptions);
  sal({
    threshold: 0.2,
  });
});

window.addEventListener('load', () => {
  window.addEventListener('scroll', () => {
    if (isInViewport(counterNum[0]) && !isDone) {
      runCounter(counterNum);
      isDone = true;
    }
  });
});
